import { template as template_71747e3903c04b36910d1c4b5d27e258 } from "@ember/template-compiler";
const FKLabel = template_71747e3903c04b36910d1c4b5d27e258(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
