import { template as template_a3ece1e245ff416398d1f1c340cd9013 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a3ece1e245ff416398d1f1c340cd9013(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
