import { template as template_91fa21c3410f480ea0c2e76c1291c801 } from "@ember/template-compiler";
import BulkSelectTopicsDropdown from "discourse/components/bulk-select-topics-dropdown";
import { i18n } from "discourse-i18n";
const TopicBulkSelectDropdown = template_91fa21c3410f480ea0c2e76c1291c801(`
  <div class="bulk-select-topics-dropdown">
    <span class="bulk-select-topic-dropdown__count">
      {{i18n
        "topics.bulk.selected_count"
        count=@bulkSelectHelper.selected.length
      }}
    </span>
    <BulkSelectTopicsDropdown
      @bulkSelectHelper={{@bulkSelectHelper}}
      @afterBulkActionComplete={{@afterBulkActionComplete}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicBulkSelectDropdown;
