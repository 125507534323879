import { template as template_2a7dd49e8be541c7a84fab0eebb20585 } from "@ember/template-compiler";
const FKText = template_2a7dd49e8be541c7a84fab0eebb20585(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
