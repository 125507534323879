import { template as template_23be86429b2e49a6b00bb7f9ed57d5bd } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_23be86429b2e49a6b00bb7f9ed57d5bd(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_23be86429b2e49a6b00bb7f9ed57d5bd(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_23be86429b2e49a6b00bb7f9ed57d5bd(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
